import "./Admin.css";
import createHtmlPage from "../../util/publishWebpage";
import { useRef, useState } from "react";
import { Preview } from "../../components/Preview/Preview";
import { PageData, readOnlyWebpageData } from "../../hooks/useWebpageData";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_WEBPAGE_QUERY,
  POST_PUBLISH_WEBPAGE_MUTATION,
  PublishData,
  QueryPageData,
} from "../../api/Requests";
import { myToastSave } from "../../util/myToast";
import { toast, ToastContainer } from "react-toastify";
import { myHash } from "../../util/myHash";

export default function Admin() {
  const [htmlString, setHtmlString] = useState<[string, number]>();
  const webpageRef = useRef<HTMLDivElement>(null);

  const { data } = useQuery<QueryPageData>(GET_WEBPAGE_QUERY, {
    variables: {
      version: 1,
    },
  });
  const [postPublish, { data: publishData }] = useMutation<PublishData>(
    POST_PUBLISH_WEBPAGE_MUTATION,
  );

  function publishWebpage() {
    if (htmlString) {
      const saveResponse = postPublish({
        variables: {
          input: {
            data: htmlString[0],
            hash: htmlString[1],
          },
        },
      });

      myToastSave(
        saveResponse,
        "Publishing...",
        "New version of webpage is live!",
      );
    } else {
      toast.error("Error generating webpage");
    }
  }

  function createHtmlString() {
    if (webpageRef?.current === null) return undefined;

    const pageHTML = createHtmlPage(
      webpageRef.current.innerHTML,
      data?.webpage.companyName,
      data?.webpage.companyName,
      data?.webpage.companyDescription,
    );
    const getHash = myHash(pageHTML);
    setHtmlString([pageHTML, getHash]);
  }

  function downloadHtml(myTemplate: string, showInConsole: boolean = false) {
    if (myTemplate) {
      if (showInConsole) {
        alert(myTemplate);
      } else {
        var tempEl = document.createElement("a");

        tempEl.href =
          "data:text/html;charset=UTF-8," + encodeURIComponent(myTemplate);
        tempEl.target = "_blank";
        tempEl.download = "easyzilla-template.html";
        tempEl.click();
      }
    }
  }

  return (
    <div className="adminContainer">
      <div className="pageItem">
        <h1>Admin</h1>
        <p>Note to myself:</p>
        <p>
          Allow user to generate html and see preview in modal, then when they
          confirm it will publish the html doc to their s3 bucket.
        </p>
        <p>First time publish should set up s3 bucket and subdomain.</p>
        <h3>Admin Commands and Experimentation</h3>
        <p>Create html template based on existing webpage component.</p>
        {data && (
          <button onClick={createHtmlString}>Prepare Webpage Html</button>
        )}
        {htmlString && (
          <div>
            <button onClick={() => downloadHtml(htmlString[0], true)}>
              Show Html
            </button>
            <button onClick={() => downloadHtml(htmlString[0], true)}>
              Download Html
            </button>
            <button onClick={publishWebpage}>Publish Webpage</button>
          </div>
        )}
        {publishData && (
          <p>Webpage Publish Data: {JSON.stringify(publishData)}</p>
        )}
      </div>
      <div className="pageItem mobilePreview">
        <p>
          Webpage Preview: (
          <span className="mobilePreviewTitle">Mobile View</span>)
        </p>
        <iframe
          className="templatePreview"
          title="templatePreview"
          srcDoc={htmlString?.[0]}
        />
        {data && (
          <div ref={webpageRef} style={{ display: "none" }}>
            <EmptyTemplate readOnlyData={data.webpage} />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

interface EmptyTemplateProps {
  readOnlyData: PageData;
}

function EmptyTemplate({ readOnlyData }: EmptyTemplateProps) {
  return (
    <Preview
      editModeEnabled={false}
      toggleEditMode={() => void 0}
      myPageData={readOnlyWebpageData(readOnlyData)}
      webpageEdited={false}
      webpageEditedAction={() => void 0}
      savePageData={() => void 0}
    />
  );
}
