import { FetchResult } from "@apollo/client";
import { toast } from "react-toastify";

export function myToastSave(
  saveResponse: Promise<FetchResult<any>>,
  pendingMessage?: string,
  successMessage?: string,
) {
  toast.promise(
    saveResponse,
    {
      pending: {
        render() {
          return pendingMessage ? pendingMessage : "Saving...";
        },
        icon: false,
      },
      success: {
        render() {
          return successMessage ? successMessage : "Successfully saved!";
        },
      },
      error: {
        render() {
          // When the promise reject, data will contains the error
          //return <MyErrorComponent message={data.message} />
          return "An error occurred.";
        },
      },
    },
    {
      position: "bottom-center",
    },
  );
}
