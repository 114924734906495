import { gql } from "@apollo/client";
import { PageData } from "../hooks/useWebpageData";

const accountPath = "account";
const webpagePath = "webpage";
const imagePath = "image";
const publishPath = webpagePath + "/publish";

export const GET_ACCOUNT_QUERY = gql`
  query Account {
    account @rest(type: "Account", path: "${accountPath}/") {
      accountId,
      email,
      createdOn,
      updatedOn,
    }
  }
`;

export const UPSERT_ACCOUNT_MUTATION = gql`
  mutation AccountUpsert($input: any!) {
    account: upsert(input: $input)
      @rest(
        type: "Account"
        path: "${accountPath}/"
        method: "PUT"
      ) {
      accountId,
      email,
      createdOn,
      updatedOn,
    }
  }
`;

export interface QueryPageData {
  webpage: PageData;
}

export const GET_WEBPAGE_QUERY = gql`
  query Webpage($version: any!) {
    webpage(version: $version) 
    @rest(type: "Webpage", path: "${webpagePath}?{args}") {
      accountId,
      webpageVersion,
      headerTitle,
      companyName,
      companyDescription,
      aboutMeTitle,
      aboutMe,
      logo,
      serviceItemsTitle,
      serviceItems,
      otherTitle,
      otherItems,
      email,
      phone,
      socialUrl,
      owner,
      addressFreeForm,
      otherImg,
      backgroundImg,
      accentColor,
    }
  }
`;

export const UPSERT_WEBPAGE_MUTATION = gql`
  mutation WebpageUpsert($input: any!) {
    webpage: upsert(input: $input)
      @rest(
        type: "Webpage"
        path: "${webpagePath}/"
        method: "PUT"
      ) {
      accountId,
      webpageVersion,
      headerTitle,
      companyName,
      companyDescription,
      aboutMeTitle,
      aboutMe,
      logo,
      serviceItemsTitle,
      serviceItems,
      otherTitle,
      otherItems,
      email,
      phone,
      socialUrl,
      owner,
      addressFreeForm,
      otherImg,
      backgroundImg,
      accentColor,
    }
  }
`;

export interface PublishData {
  publish: {
    publishVersion: string;
    publishUrl: string;
  };
}

export const POST_PUBLISH_WEBPAGE_MUTATION = gql`
  mutation PublishPost($input: any!) {
    publish: post(input: $input)
      @rest(
        type: "Publish"
        path: "${publishPath}/"
        method: "POST"
      ) {
      publishVersion,
      publishUrl,
    }
  }
`;

export interface PreSignedUrlData {
  image: {
    presignedUrl: string;
    fullKey: string;
    errorMessage?: string;
  };
}

export const POST_PRE_SIGNED_URL_MUTATION = gql`
  mutation ImagePost($input: any!) {
    image: post(input: $input)
      @rest(
        type: "Image"
        path: "${imagePath}/"
        method: "POST"
      ) {
      presignedUrl,
      fullKey,
    }
  }
`;
