import { useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_WEBPAGE_QUERY, UPSERT_WEBPAGE_MUTATION } from "../api/Requests";
import { myToastSave } from "../util/myToast";

export const myPageDataEnum = {
  headerTitle: "headerTitle",
  companyName: "companyName",
  companyDescription: "companyDescription",
  aboutMeTitle: "aboutMeTitle",
  aboutMe: "aboutMe",
  logo: "logo",
  serviceItemsTitle: "serviceItemsTitle",
  serviceItems: "serviceItems",
  otherTitle: "otherTitle",
  otherImg: "otherImg",
  phone: "phone",
  email: "email",
  socialUrl: "socialUrl",
  owner: "owner",
  addressFreeForm: "addressFreeForm",
  accentColor: "accentColor",
  backgroundImg: "backgroundImg",
  otherItems: "otherItems",
};

export interface PageData {
  headerTitle: string;
  companyName: string;
  companyDescription: string;
  aboutMeTitle: string;
  aboutMe: string;
  logo: string;
  serviceItemsTitle: string;
  serviceItems: {
    index: number;
    title: string;
    description: string;
    img: string;
  }[];
  otherTitle: string;
  otherItems: {
    title: string;
    description: string;
  }[];
  email: string;
  phone: string;
  socialUrl: string;
  owner: string;
  addressFreeForm: string;
  otherImg: string;
  backgroundImg: string;
  accentColor: string;
}

function transformMyPageData(bigMapInput: Map<any, any>) {
  return {
    headerTitle: bigMapInput.get(myPageDataEnum.headerTitle)?.value,
    companyName: bigMapInput.get(myPageDataEnum.companyName)?.value,
    companyDescription: bigMapInput.get(myPageDataEnum.companyDescription)
      ?.value,
    aboutMeTitle: bigMapInput.get(myPageDataEnum.aboutMeTitle)?.value,
    aboutMe: bigMapInput.get(myPageDataEnum.aboutMe)?.value,
    logo: bigMapInput.get(myPageDataEnum.logo)?.value,
    serviceItemsTitle: bigMapInput.get(myPageDataEnum.serviceItemsTitle)?.value,
    serviceItems: bigMapInput.get(myPageDataEnum.serviceItems)?.value,
    otherTitle: bigMapInput.get(myPageDataEnum.otherTitle)?.value,
    otherItems: bigMapInput.get(myPageDataEnum.otherItems)?.value,
    email: bigMapInput.get(myPageDataEnum.email)?.value,
    phone: bigMapInput.get(myPageDataEnum.phone)?.value,
    socialUrl: bigMapInput.get(myPageDataEnum.socialUrl)?.value,
    owner: bigMapInput.get(myPageDataEnum.owner)?.value,
    addressFreeForm: bigMapInput.get(myPageDataEnum.addressFreeForm)?.value,
    otherImg: bigMapInput.get(myPageDataEnum.otherImg)?.value,
    backgroundImg: bigMapInput.get(myPageDataEnum.backgroundImg)?.value,
    accentColor: bigMapInput.get(myPageDataEnum.accentColor)?.value,
  };
}

export function readOnlyWebpageData(readOnlyData: PageData) {
  const bigMapInput = new Map();
  bigMapInput.set(myPageDataEnum.headerTitle, {
    value: readOnlyData.headerTitle,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.companyName, {
    value: readOnlyData.companyName,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.companyDescription, {
    value: readOnlyData.companyDescription,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.aboutMeTitle, {
    value: readOnlyData.aboutMeTitle,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.aboutMe, {
    value: readOnlyData.aboutMe,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.logo, {
    value: readOnlyData.logo,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.serviceItemsTitle, {
    value: readOnlyData.serviceItemsTitle,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.serviceItems, {
    value: readOnlyData.serviceItems,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.otherTitle, {
    value: readOnlyData.otherTitle,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.otherItems, {
    value: readOnlyData.otherItems,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.email, {
    value: readOnlyData.email,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.phone, {
    value: readOnlyData.phone,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.socialUrl, {
    value: readOnlyData.socialUrl,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.owner, {
    value: readOnlyData.owner,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.addressFreeForm, {
    value: readOnlyData.addressFreeForm,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.otherImg, {
    value: readOnlyData.otherImg,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.backgroundImg, {
    value: readOnlyData.backgroundImg,
    setter: () => {},
  });
  bigMapInput.set(myPageDataEnum.accentColor, {
    value: readOnlyData.accentColor,
    setter: () => {},
  });
  return bigMapInput;
}

export function useWebpageData(inputData?: PageData) {
  const [webpageCreate, { loading, error }] = useMutation(
    UPSERT_WEBPAGE_MUTATION,
  );
  const myPageData = new Map();
  // Company Info
  const [headerTitle, setHeaderTitle] = useState(inputData?.headerTitle);
  myPageData.set(myPageDataEnum.headerTitle, {
    value: headerTitle,
    setter: setHeaderTitle,
  });

  const [companyName, setCompanyName] = useState(inputData?.companyName);
  myPageData.set(myPageDataEnum.companyName, {
    value: companyName,
    setter: setCompanyName,
  });
  const [companyDescription, setCompanyDescription] = useState(
    inputData?.companyDescription,
  );
  myPageData.set(myPageDataEnum.companyDescription, {
    value: companyDescription,
    setter: setCompanyDescription,
  });

  const [logo, setLogo] = useState(inputData?.logo);
  myPageData.set(myPageDataEnum.logo, { value: logo, setter: setLogo });

  const [aboutMeTitle, setAboutMeTitle] = useState(inputData?.aboutMeTitle);
  myPageData.set(myPageDataEnum.aboutMeTitle, {
    value: aboutMeTitle,
    setter: setAboutMeTitle,
  });
  const [aboutMe, setAboutMe] = useState(inputData?.aboutMe);
  myPageData.set(myPageDataEnum.aboutMe, {
    value: aboutMe,
    setter: setAboutMe,
  });

  // Contact Info
  const [phone, setPhone] = useState(inputData?.phone);
  myPageData.set(myPageDataEnum.phone, { value: phone, setter: setPhone });
  const [email, setEmail] = useState(inputData?.email);
  myPageData.set(myPageDataEnum.email, { value: email, setter: setEmail });
  const [owner, setOwner] = useState(inputData?.owner);
  myPageData.set(myPageDataEnum.owner, { value: owner, setter: setOwner });
  const [addressFreeForm, setAddressFreeForm] = useState(
    inputData?.addressFreeForm,
  );
  myPageData.set(myPageDataEnum.addressFreeForm, {
    value: addressFreeForm,
    setter: setAddressFreeForm,
  });
  const [socialUrl, setSocialUrl] = useState(inputData?.socialUrl);
  myPageData.set(myPageDataEnum.socialUrl, {
    value: socialUrl,
    setter: setSocialUrl,
  });

  // Service Items
  const [serviceItemsTitle, setServiceItemsTitle] = useState(
    inputData?.serviceItemsTitle,
  );
  myPageData.set(myPageDataEnum.serviceItemsTitle, {
    value: serviceItemsTitle,
    setter: setServiceItemsTitle,
  });

  const [serviceItems, setServiceItems] = useState(
    inputData?.serviceItems ?? [],
  );
  myPageData.set(myPageDataEnum.serviceItems, {
    value: serviceItems,
    setter: setServiceItems,
  });

  // Misc Page Data
  const [otherTitle, setOtherTitle] = useState(inputData?.otherTitle);
  myPageData.set(myPageDataEnum.otherTitle, {
    value: otherTitle,
    setter: setOtherTitle,
  });

  const [otherImg, setOtherImg] = useState(inputData?.otherImg);
  myPageData.set(myPageDataEnum.otherImg, {
    value: otherImg,
    setter: setOtherImg,
  });

  // TODO: Set up other items instead of the ContactItems... then make sure the Contact Data is displayed explicitly in Preview...
  const [otherItems, setOtherItems] = useState(inputData?.otherItems ?? []);
  myPageData.set(myPageDataEnum.otherItems, {
    value: otherItems,
    setter: setOtherItems,
  });

  // Advanced page settings
  const [backgroundImg, setBackgroundImg] = useState(
    inputData?.backgroundImg ?? `${process.env.PUBLIC_URL}/default-bg.jpg`,
  );
  myPageData.set(myPageDataEnum.backgroundImg, {
    value: backgroundImg,
    setter: setBackgroundImg,
  });

  const [accentColor, setAccentColor] = useState(
    inputData?.accentColor ?? "#F7F7F7",
  );
  myPageData.set(myPageDataEnum.accentColor, {
    value: accentColor,
    setter: setAccentColor,
  });

  function saveMyPageData() {
    // We are refetching the main query to update apollo caching state
    const saveResponse = webpageCreate({
      variables: {
        input: transformMyPageData(myPageData),
      },
      refetchQueries: [GET_WEBPAGE_QUERY],
    });

    myToastSave(saveResponse);
  }

  return {
    myPageData,
    saveMyPageData,
    loading,
    error,
  };
}
