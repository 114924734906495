import template from "./template.json";

// TODO: Offload this work to a lambda...
export default function createHtmlPage(
  pageContent,
  author,
  pageTitle,
  pageDescription,
) {
  const parser = new DOMParser();
  // TODO: Add icon functionality
  const styleVersion = 1;
  // TODO: Make sure to deploy latest style publicly for this to work
  const templateString = `<head>${template.metaData}<link rel='stylesheet' href='https://easyzilla.com/styles/v${styleVersion}.css'></head><body class='webpageRoot'>${pageContent}</body>`;
  const parsedTemplate = parser.parseFromString(templateString, "text/html");
  // Update metadata
  parsedTemplate.title = pageTitle;
  parsedTemplate.getElementsByTagName("meta").author.content = author;
  parsedTemplate.getElementsByTagName("meta").description.content =
    pageDescription;

  return parsedTemplate.documentElement.outerHTML;
}
