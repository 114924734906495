import { ToastContainer } from "react-toastify";
import "./Dashboard.css";
import { myToastSave } from "../../../util/myToast";
import { useMutation } from "@apollo/client";
import {
  POST_PUBLISH_WEBPAGE_MUTATION,
  PublishData,
} from "../../../api/Requests";

export default function Dashboard() {
  const [postPublish, { data, error }] = useMutation<PublishData>(
    POST_PUBLISH_WEBPAGE_MUTATION,
  );
  // TODO: Add getPublish to get versionReadyForPublish
  const versionReadyForPublish = 1;

  function publishWebpage() {
    const saveResponse = postPublish({
      variables: {
        input: {},
      },
      // TODO: Have this refresh account data to show new published version
      //refetchQueries: [GET_ACCOUNT_QUERY],
    });

    myToastSave(
      saveResponse,
      "Publishing...",
      "New version of webpage is live!",
    );
  }

  return (
    <div className="accountContainer">
      <h1>Home</h1>
      <p>
        Webpage Version <b>v{versionReadyForPublish}</b> is ready to be
        published.
      </p>
      <button onClick={publishWebpage}>Publish Webpage</button>
      {data && <p>Webpage Publish Data: {JSON.stringify(data)}</p>}
      {error && <p>Webpage Publish Error: {JSON.stringify(error)}</p>}
      <p>Live website preview</p>
      <p>Current version (not yet published)</p>
      <div>
        <iframe
          style={{
            width: "500px",
            height: "500px",
          }}
          title="publishPreview"
          // TODO: Should show "webpage under construction" if there isn't a published webpage
          src={undefined} //"https://easyzilla.com"
          //srcDoc={createHtmlPreview()}
        />
      </div>
      {/*
      <h2>Webpage Analytics</h2>
      <p>
        Current published version: <b>(nothing publish yet)</b>
      </p>
      <p>
        Webpage views: <b>0</b>
      </p>
      */}
      <ToastContainer />
    </div>
  );
}
