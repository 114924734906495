import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { ApolloProvider } from "@apollo/client";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import ExampleMode from "./pages/Home/ExampleMode";
import FreshMode from "./pages/Home/FreshMode";

import Home from "./pages/Home/Home";
import Account from "./pages/Home/Account/Account";
import AuthProvider from "./auth/AuthProvider";
import client from "./api/ApiClient";
import Dashboard from "./pages/Home/Dashboard/Dashboard";
import { ErrorPage } from "./ErrorPage";
import Admin from "./pages/Admin/Admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "home",
    element: <Home />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "webpage",
        element: <FreshMode />,
      },
      {
        path: "example",
        element: <ExampleMode />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </AuthProvider>,
);
