import { Link, unstable_usePrompt, useOutletContext } from "react-router-dom";
import "./Account.css";
import { useAuth } from "../../../auth/AuthProvider";
import {
  RenderEditImage,
  RenderEditItem,
} from "../../../components/Preview/components/RenderEdits";
import { useState } from "react";
import { iconRenderMapper } from "../../../util/iconMapper";
import {
  myPageDataEnum,
  PageData,
  useWebpageData,
} from "../../../hooks/useWebpageData";
import { useQuery } from "@apollo/client";
import { GET_WEBPAGE_QUERY, QueryPageData } from "../../../api/Requests";
import { HomeOutletContext } from "../Home";
import { ToastContainer } from "react-toastify";

export default function Account() {
  const { session } = useAuth();
  const contextData = useOutletContext<HomeOutletContext>();
  const webpageVersion = 1;
  // TODO: Make sure to update query to include email by default if not found
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery<QueryPageData>(GET_WEBPAGE_QUERY, {
    variables: {
      version: webpageVersion,
    },
    skip: contextData === null,
  });

  return (
    <div className="accountContainer">
      <h1>Account</h1>
      {session?.user?.email && <h2>Hi, {session?.user?.email}</h2>}
      <p>
        Welcome to your account page, update personal and company information
        here.
      </p>
      <button type="button" disabled>
        Import google business profile (coming soon)
      </button>
      {queryData?.webpage && !queryLoading && !queryError && (
        <CompanyWebpageData
          inputData={queryData.webpage}
          webpageVersion={webpageVersion}
        />
      )}
      {!contextData && <p>Loading...</p>}
      {!!contextData && (
        <div className="accountInfoCard">
          <h3>Easyzilla Account Info</h3>
          <Link className="homeButton" to="/logout">
            Logout
          </Link>
          <p>
            <b>Account Email</b> {contextData.account.email}
          </p>
          <p>
            <b>Account Updated</b> {contextData.account.updatedOn}
          </p>
          <p>
            <b>Account Created</b> {contextData.account.createdOn}
          </p>
          <p>
            <b>Subscription Tier</b> Free
          </p>
          <p>
            <b>Subscription Status</b> Active
          </p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

interface CompanyWebpageDataProps {
  inputData: PageData;
  webpageVersion: number;
}

function CompanyWebpageData({
  inputData,
  webpageVersion,
}: CompanyWebpageDataProps) {
  const { myPageData, saveMyPageData } = useWebpageData(inputData);
  const [accountInfoEdited, setAccountInfoEdited] = useState(false);

  // TODO: Replace with better implementation
  unstable_usePrompt({
    message: "You have unsaved changes, are you sure you want to leave?",
    when: accountInfoEdited,
  });

  function accountEditedAction() {
    setAccountInfoEdited(true);
  }

  function saveAccountInfo() {
    saveMyPageData();
    setAccountInfoEdited(false);
  }

  function resetAccountInfo() {
    window.location.reload();
  }

  return (
    <div className="accountInfoCard">
      <h3>Your Company Info</h3>
      <p>This data will be used to personalize your webpage.</p>
      <p className="webpageVersionText">
        Current Webpage Version: {webpageVersion}
      </p>
      {accountInfoEdited && (
        <span>
          <button onClick={saveAccountInfo}>Save Changes</button>{" "}
          <button onClick={resetAccountInfo}>Reset Changes</button>
        </span>
      )}
      {/* TODO: Make label naming only show with tool tip hovering*/}
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("flag")} Company Name
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.companyName)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("text")} Tagline / Company Description
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.companyDescription)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("user")} Owner Name
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.owner)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("location")} Located / Address
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.addressFreeForm)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("email")} Company Email
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.email)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("phone")} Phone
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.phone)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("link")} External Link
        </p>
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.socialUrl)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("flag-fill")} Company Color
        </p>
        <input
          className="accountInputColor"
          type="color"
          value={myPageData.get(myPageDataEnum.accentColor).value}
          onChange={e =>
            myPageData
              .get(myPageDataEnum.accentColor)
              .setter(e.currentTarget.value)
          }
          onClick={accountEditedAction}
        />
        <RenderEditItem
          inputItem={myPageData.get(myPageDataEnum.accentColor)}
          saveAction={accountEditedAction}
        />
      </span>
      <span className="accountInfoLine">
        <p className="accountInfoLineLabel">
          {iconRenderMapper("flag-alt")} Logo
        </p>
      </span>
      <span className="accountInfoLine">
        <div className="accountInfoImg">
          <RenderEditImage
            inputSrc={myPageData.get(myPageDataEnum.logo)}
            altText="logo"
            saveAction={accountEditedAction}
          />
        </div>
      </span>
    </div>
  );
}
