import { useLayoutEffect, useRef, useState } from "react";
import type { PageData } from "../../hooks/useWebpageData";
import { myPageDataEnum, useWebpageData } from "../../hooks/useWebpageData";
import { Preview } from "./Preview";
import { RenderEditImage, RenderEditItem } from "./components/RenderEdits";

import "./PreviewWrapper.css";
import { unstable_usePrompt } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { TfiClose } from "react-icons/tfi";

interface PreviewWrapperProps {
  editEnabled?: boolean;
  previewData?: PageData;
}

export default function PreviewWrapper({
  editEnabled = false,
  previewData,
}: PreviewWrapperProps) {
  const { myPageData, saveMyPageData } = useWebpageData(previewData);
  const [editModeEnabled, setEditModeEnabled] = useState(editEnabled);

  // START********Preview Menu Deps********
  const ref = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth);
    }
  }, []);

  function toggleOpenMenu(onlyMobile = false) {
    if (!openMenu) {
      // Set edit to true when opening menu
      setEditModeEnabled(true);
    }
    if (onlyMobile) {
      if (width < 600) {
        setOpenMenu(val => !val);
      }
    } else {
      setOpenMenu(val => !val);
    }
  }
  // END********Preview Menu Deps********
  const [webpageEdited, setWebpageEdited] = useState(false);
  function webpageEditedAction() {
    setWebpageEdited(true);
  }
  // TODO: Replace with better implementation
  unstable_usePrompt({
    message: "You have unsaved changes, are you sure you want to leave?",
    when: webpageEdited,
  });

  function savePageData() {
    saveMyPageData();
    setWebpageEdited(false);
  }

  function toggleEditMode() {
    setEditModeEnabled(val => !val);
  }

  return (
    <div ref={ref}>
      <PreviewMenu
        openMenu={openMenu}
        toggleOpenMenu={toggleOpenMenu}
        savePageData={savePageData}
        myPageData={myPageData}
        webpageEdited={webpageEdited}
        webpageEditedAction={webpageEditedAction}
      />
      <ToastContainer />
      <Preview
        editModeEnabled={editModeEnabled}
        toggleEditMode={toggleEditMode}
        myPageData={myPageData}
        webpageEdited={webpageEdited}
        webpageEditedAction={webpageEditedAction}
        savePageData={savePageData}
      />
    </div>
  );
}

interface PreviewMenuProps {
  openMenu: boolean;
  toggleOpenMenu: (onlyMobile?: boolean) => void;
  savePageData: () => void;
  myPageData: Map<any, any>;
  webpageEdited: boolean;
  webpageEditedAction: () => void;
}

function PreviewMenu({
  openMenu,
  toggleOpenMenu,
  savePageData,
  myPageData,
  webpageEdited,
  webpageEditedAction,
}: PreviewMenuProps) {
  return (
    <>
      <button
        type="button"
        className="sideBarMobileButton"
        onClick={() => toggleOpenMenu()}
        style={{
          backgroundColor: openMenu ? "unset" : "#0538af",
          color: openMenu ? "#0538af" : "white",
        }}
      >
        {openMenu ? <TfiClose /> : <HiMiniWrenchScrewdriver />}
      </button>
      <div className="sideBar" style={{ display: openMenu ? "block" : "none" }}>
        <div className="sideBarTitle">
          <p>Webpage Settings</p>
          {/* <button onClick={() => togglePreviewMode()}>Publish Webpage</button> */}
          {webpageEdited && (
            <button type="button" onClick={() => savePageData()}>
              Save Changes
            </button>
          )}
        </div>
        <div className="sideBarDash" />
        <div className="sideBarOptions">
          <div className="sideBarOption">
            <p>Company Color</p>
            <input
              type="color"
              value={myPageData.get(myPageDataEnum.accentColor).value}
              onChange={e =>
                myPageData
                  .get(myPageDataEnum.accentColor)
                  .setter(e.currentTarget.value)
              }
              onClick={webpageEditedAction}
            />
            <RenderEditItem
              inputItem={myPageData.get(myPageDataEnum.accentColor)}
              saveAction={webpageEditedAction}
            />
          </div>
          <div className="sideBarOption">
            <p>Background Image: </p>
            <div className="settingsEditBackground">
              <RenderEditImage
                inputSrc={myPageData.get(myPageDataEnum.backgroundImg)}
                altText="background"
                saveAction={webpageEditedAction}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
