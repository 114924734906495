import { useQuery } from "@apollo/client";
import PreviewWrapper from "../../components/Preview/PreviewWrapper";
import { GET_WEBPAGE_QUERY, QueryPageData } from "../../api/Requests";
import { useEffect } from "react";

export default function FreshMode() {
  // TODO: Right now this rerenders everytime the FreshMode is called (we may want "limbo" data to be saved until website should be published)
  // The query data is cached after first load... which is great, but we need to fix "limbo" data
  const { loading, error, data } = useQuery<QueryPageData>(GET_WEBPAGE_QUERY, {
    variables: {
      version: 1,
    },
  });

  // Allow the top menu to scroll out of view so the webpage is seen fully
  useEffect(() => {
    if (data && document !== null) {
      document.getElementById("top")?.scrollIntoView();
    }
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }
  return error ? (
    <p>Error: {error.message}</p>
  ) : (
    data && <PreviewWrapper editEnabled previewData={data.webpage} />
  );
}
