import { useState, useRef, useEffect, useCallback } from "react";
import {
  POST_PRE_SIGNED_URL_MUTATION,
  PreSignedUrlData,
} from "../../../api/Requests";
import { useMutation } from "@apollo/client";

interface RenderEditItemProps {
  editModeEnabled?: boolean;
  inputItem: any;
  textAreaRows?: number;
  saveAction?: (itemOverride?: any) => void;
}

// inputItem Map<value, setter()>
// saveAction? () => void
export function RenderEditItem({
  editModeEnabled = true,
  inputItem,
  textAreaRows = 0,
  saveAction,
}: RenderEditItemProps) {
  const [editTarget, setEditTarget] = useState(false);

  function toggleOffAndSave() {
    setEditTarget(false);
    // Trigger additional save action if provided
    saveAction?.();
  }

  return editModeEnabled ? (
    <div
      onBlur={() => toggleOffAndSave()}
      onKeyDown={e => {
        if (e.key === "Enter") {
          toggleOffAndSave();
        }
      }}
      className={`editSelector ${editTarget ? "editActive" : "preEditSelector"}`}
      onClick={() => setEditTarget(true)}
    >
      {editTarget ? (
        <div>
          {textAreaRows > 0 ? (
            <textarea
              name="editTextArea"
              className="editTextArea"
              rows={textAreaRows}
              placeholder="Enter Text"
              defaultValue={inputItem?.value}
              onChange={val => inputItem?.setter(val.target.value)}
              autoFocus
            />
          ) : (
            <input
              name="editInput"
              placeholder="Enter Text"
              defaultValue={inputItem?.value}
              onChange={val => inputItem?.setter(() => val.target.value)}
              autoFocus
            />
          )}
        </div>
      ) : inputItem?.value ? (
        inputItem.value
      ) : (
        <div className="editActive editEmpty">click to edit</div>
      )}
    </div>
  ) : (
    <div>{inputItem?.value}</div>
  );
}

async function uploadImage(url: string, file: File) {
  try {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
    });
    return response.ok;
  } catch (error) {
    console.error("Error uploading image", error);
    return false;
  }
}

interface RenderEditImageProps {
  editModeEnabled?: boolean;
  inputSrc: any;
  altText?: string;
  editToolsOutside?: boolean;
  itemClass?: string;
  saveAction?: (imgOverride?: any) => void;
}
// inputSrc is Map<value, setter()>
export function RenderEditImage({
  editModeEnabled = true,
  inputSrc,
  altText = "",
  editToolsOutside = false,
  itemClass = "",
  saveAction = undefined,
}: RenderEditImageProps) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [editTarget, setEditTarget] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [postPreSignedUrl, { data, loading, error }] =
    useMutation<PreSignedUrlData>(POST_PRE_SIGNED_URL_MUTATION);

  const handleSaveAndClose = useCallback(
    (fileUrl: string) => {
      console.log("handleSaveAndClose", fileUrl);
      inputSrc?.setter(fileUrl);
      setEditTarget(false);
      // Trigger additional save action if provided
      saveAction?.(fileUrl);
    },
    [inputSrc, saveAction],
  );

  useEffect(() => {
    // Handle image upload
    if (data && !loading && !error && !imageUploading && imageFile) {
      if (data.image?.presignedUrl && data.image?.fullKey) {
        setImageUploading(true);
        uploadImage(data.image.presignedUrl, imageFile).then(success => {
          if (success) {
            const urlWithFullKey = `https://d34pl9m09oi7oj.cloudfront.net/${data.image.fullKey}`;
            handleSaveAndClose(urlWithFullKey);
          } else {
            alert("Image upload failed");
          }
          setImageUploading(false);
          setImageFile(null);
        });
      } else {
        alert("Image upload error");
      }
    }
  }, [
    data,
    loading,
    error,
    editTarget,
    handleSaveAndClose,
    imageUploading,
    imageFile,
  ]);

  function handleRemoveAndClose() {
    setEditTarget(false);
    inputSrc?.setter("");
    // Trigger additional save action if provided
    saveAction?.("empty");
  }

  function handleInputFile() {
    if (inputFileRef && inputFileRef.current !== null) {
      inputFileRef.current.click();
    }
  }

  function handleOpenEdit(hasImage = false) {
    if (hasImage) {
      setEditTarget(val => !val);
    } else {
      handleInputFile();
    }
  }
  return (
    <div className={itemClass}>
      {(loading || imageUploading) && <div>Uploading Image...</div>}
      {editModeEnabled ? (
        <div className="editImage">
          <input
            type="file"
            ref={inputFileRef}
            accept="image/*"
            onChange={event => {
              const myFile = event?.target?.files?.item(0);
              if (myFile) {
                // Fetch pre-signed URL (handled by useEffect)
                postPreSignedUrl({
                  variables: {
                    input: {
                      myKey: myFile.name, // example: example-key2.png
                      myContentType: myFile.type, // example: image/png
                      footPrint: myFile.size, // bytes
                    },
                  },
                });
                setImageFile(myFile);
              } else {
                alert("Issue retrieving image");
              }
            }}
            style={{ display: "none" }}
          />
          {editTarget && !editToolsOutside && (
            <button
              type="button"
              className="editCloseButton editImageAddButton"
              onClick={() => handleInputFile()}
            >
              Upload New Image
            </button>
          )}
          {editTarget && !editToolsOutside && (
            <button
              type="button"
              className="editCloseButton editImageRemoveButton"
              onClick={() => handleRemoveAndClose()}
            >
              Clear Image
            </button>
          )}
          {/* eslint-disable-next-line */}
          <img
            className={`editSelector ${editTarget ? "editActive" : "preEditSelector"}`}
            onClick={() => handleOpenEdit(inputSrc?.value)}
            src={inputSrc?.value}
            alt={`🖼️ Add image for "${altText}"`}
          />
          {editTarget && editToolsOutside && (
            <button
              type="button"
              className="editCloseButton"
              onClick={() => handleInputFile()}
            >
              Upload New Image
            </button>
          )}
          {editTarget && editToolsOutside && (
            <button
              type="button"
              className="editCloseButton"
              onClick={() => handleRemoveAndClose()}
            >
              Clear Image
            </button>
          )}
        </div>
      ) : (
        inputSrc?.value && <img src={inputSrc?.value} alt="" />
      )}
    </div>
  );
}
