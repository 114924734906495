import { useState } from "react";
import { RenderEditItem, RenderEditImage } from "./components/RenderEdits";
import { RenderSectionItems } from "./components/RenderItems";
import { BsChevronCompactDown } from "react-icons/bs";

import "./Preview.css";
import "./PreviewEdit.css";
import { myPageDataEnum } from "../../hooks/useWebpageData";

interface PreviewProps {
  editModeEnabled: boolean;
  toggleEditMode: () => void;
  // TODO: convert to better type
  myPageData: Map<any, any>;
  webpageEdited: boolean;
  webpageEditedAction: () => void;
  savePageData: () => void;
}

export function Preview({
  editModeEnabled,
  toggleEditMode,
  myPageData,
  webpageEdited,
  webpageEditedAction,
  savePageData,
}: PreviewProps) {
  // TODO: Replace these with webpage sectionEnabled data points
  const [aboutMeSectionEnabled, setAboutMeSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.aboutMe)?.value,
  );
  const [serviceSectionEnabled, setServiceSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.serviceItems)?.value.length > 0,
  );
  const [bottomSectionEnabled, setBottomSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.otherItems)?.value.length > 0,
  );

  // TODO: Set these as savable data points
  const primaryColor = "247, 247, 247";
  const secondaryColor = "247, 247, 247";
  const primaryFont = "247, 247, 247";
  const secondaryFont = "0, 0, 0";

  return (
    <div
      id="webpage"
      className="webpage"
      style={
        myPageData.get(myPageDataEnum.backgroundImg)?.value
          ? {
              backgroundImage: `url(${myPageData.get(myPageDataEnum.backgroundImg)?.value})`,
            }
          : {
              backgroundImage: `linear-gradient(45deg, #f5f5f5 0%, ${myPageData.get(myPageDataEnum.accentColor)?.value ?? "#fff"} 50%)`,
            }
      }
    >
      <div id="top" />
      <div className="topHeader">
        <div className="topHeaderContent">
          <div className="topHeaderTitle">
            <a href={!editModeEnabled ? "#top" : "#edit"}>
              <div className="topHeaderImg">
                <RenderEditImage
                  inputSrc={myPageData.get(myPageDataEnum.logo)}
                  altText="logo"
                  editModeEnabled={editModeEnabled}
                  editToolsOutside
                  saveAction={webpageEditedAction}
                />
              </div>
            </a>
            <a href={!editModeEnabled ? "#top" : "#edit"}>
              <h2>
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.companyName)}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </h2>
            </a>
          </div>
          <div className="topHeaderMenu">
            {aboutMeSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${myPageData.get(myPageDataEnum.aboutMeTitle)?.value}`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
                </a>
              </div>
            )}
            {serviceSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${
                    myPageData.get(myPageDataEnum.serviceItemsTitle)?.value
                  }`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
                </a>
              </div>
            )}
            {bottomSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${myPageData.get(myPageDataEnum.otherTitle)?.value}`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.otherTitle)?.value}
                </a>
              </div>
            )}
            <div className="topHeaderMenuItem">
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href="#footer"
                rel="author"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="topHeaderMenuMobile">
            <a
              style={{ color: `rgb(${secondaryFont})` }}
              href="#footer"
              rel="author"
            >
              Contact Us
            </a>
          </div>
          <div className="topHeaderContact">
            <div>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled
                    ? `mailto:${myPageData.get(myPageDataEnum.email)?.value}`
                    : "#edit"
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.email)}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </a>
            </div>
            <div>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled
                    ? `tel:${myPageData.get(myPageDataEnum.phone)?.value}`
                    : "#edit"
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.phone)}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  myPageData.get(myPageDataEnum.socialUrl)?.value
                }
                rel="noreferrer"
              >
                {editModeEnabled ? (
                  <RenderEditItem
                    inputItem={myPageData.get(myPageDataEnum.socialUrl)}
                    editModeEnabled={editModeEnabled}
                    saveAction={webpageEditedAction}
                  />
                ) : (
                  "social"
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="editModeHeader"
        style={{ display: editModeEnabled ? "flex" : "none" }}
      >
        Edit Mode Enabled{" "}
        <button type="button" onClick={() => toggleEditMode()}>
          Click to close edit mode
        </button>
        {webpageEdited && (
          <button type="button" onClick={() => savePageData()}>
            Save Changes
          </button>
        )}
      </div>
      <div
        className="top"
        style={{
          backgroundColor: `rgba(${primaryColor}, 0.2)`,
          color: `rgb(${primaryFont})`,
        }}
      >
        <div className="topContainer">
          <div className="topTitle">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.companyName)}
                textAreaRows={2}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </h1>
          </div>
          {(myPageData.get(myPageDataEnum.companyDescription)?.value ||
            editModeEnabled) && (
            <div className="topDesc">
              <h2>
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.companyDescription)}
                  textAreaRows={3}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </h2>
            </div>
          )}
        </div>
        <div className="coreButton">
          {(aboutMeSectionEnabled ||
            serviceSectionEnabled ||
            bottomSectionEnabled) && (
            <a
              className="mainButton"
              style={{
                backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
                color: `rgb(${secondaryFont})`,
              }}
              href={`#${
                aboutMeSectionEnabled
                  ? myPageData.get(myPageDataEnum.aboutMeTitle)?.value
                  : serviceSectionEnabled
                    ? myPageData.get(myPageDataEnum.serviceItemsTitle)?.value
                    : "footer"
              }`}
              rel="author"
            >
              <BsChevronCompactDown />
            </a>
          )}
        </div>
      </div>
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setAboutMeSectionEnabled((val: any) => !val)}
        >
          {aboutMeSectionEnabled
            ? "Remove About Me Section"
            : "Enable About Me Section"}
        </button>
      )}
      {aboutMeSectionEnabled && (
        <div
          className="aboutMe"
          id={myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
          style={{
            backgroundColor: `rgba(${secondaryColor}, 1)`,
            color: `rgb(${secondaryFont})`,
          }}
        >
          <div className="aboutMeContent">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.aboutMeTitle)}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </h1>
            <p>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.aboutMe)}
                editModeEnabled={editModeEnabled}
                textAreaRows={5}
                saveAction={webpageEditedAction}
              />
            </p>
          </div>
          <div className="coreButton">
            {serviceSectionEnabled && (
              <a
                className="mainButton"
                style={{
                  backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
                  color: `rgb(${secondaryFont})`,
                }}
                href={`#${myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}`}
                rel="author"
              >
                <BsChevronCompactDown />
              </a>
            )}
          </div>
        </div>
      )}
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setServiceSectionEnabled(val => !val)}
        >
          {serviceSectionEnabled
            ? "Remove Service Section"
            : "Enable Service Section"}
        </button>
      )}
      {serviceSectionEnabled && (
        <div
          className="mid"
          id={myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
          style={{
            backgroundColor: `rgba(${secondaryColor}, 0.9)`,
            color: `rgb(${secondaryFont})`,
          }}
        >
          <div className="midTitle">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.serviceItemsTitle)}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </h1>
          </div>
          <div className="midItems">
            <RenderSectionItems
              inputItems={myPageData.get(myPageDataEnum.serviceItems)}
              editModeEnabled={editModeEnabled}
              enableImgs
              itemClass="midItem"
              saveAction={webpageEditedAction}
            />
          </div>
        </div>
      )}
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setBottomSectionEnabled(val => !val)}
        >
          {bottomSectionEnabled
            ? "Remove Bottom Section"
            : "Enable Bottom Section"}
        </button>
      )}
      {bottomSectionEnabled && (
        <div
          className="otherSection"
          id={myPageData.get(myPageDataEnum.otherTitle)?.value}
          style={{
            backgroundColor: `rgba(${primaryColor}, 1)`,
            color: `rgb(${primaryFont})`,
          }}
        >
          <div className="otherTitle">
            <h1 style={{ color: `rgb(${secondaryFont})` }}>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.otherTitle)}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </h1>
          </div>
          <div className="otherContent">
            <div className="otherItems">
              {/* Add more robust icon solution */}
              <RenderSectionItems
                inputItems={myPageData.get(myPageDataEnum.otherItems)}
                editModeEnabled={editModeEnabled}
                enableIcons={false}
                itemClass="otherItem"
                accentColor={myPageData.get(myPageDataEnum.accentColor)?.value}
                saveAction={webpageEditedAction}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="footer"
        id="footer"
        style={{
          backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
          color: `rgb(${secondaryFont})`,
        }}
      >
        <div className="footerContent">
          <div className="footerImg">
            <RenderEditImage
              inputSrc={myPageData.get(myPageDataEnum.logo)}
              altText={"logo"}
              editModeEnabled={editModeEnabled}
              saveAction={webpageEditedAction}
            />
            <h3>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.companyName)}
                //textAreaRows={2}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </h3>
          </div>
          <div className="footerContact">
            <h4>Contact Us</h4>
            <p>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.addressFreeForm)}
                editModeEnabled={editModeEnabled}
                saveAction={webpageEditedAction}
              />
            </p>
            <p>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled
                    ? `mailto:${myPageData.get(myPageDataEnum.email)?.value}`
                    : "#edit"
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.email)}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </a>
            </p>
            <p>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled
                    ? `tel:${myPageData.get(myPageDataEnum.phone)?.value}`
                    : "#edit"
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.phone)}
                  editModeEnabled={editModeEnabled}
                  saveAction={webpageEditedAction}
                />
              </a>
            </p>
            <p>
              <a
                target="_blank"
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  myPageData.get(myPageDataEnum.socialUrl)?.value
                }
                rel="noreferrer"
              >
                {editModeEnabled ? (
                  <RenderEditItem
                    inputItem={myPageData.get(myPageDataEnum.socialUrl)}
                    editModeEnabled={editModeEnabled}
                    saveAction={webpageEditedAction}
                  />
                ) : (
                  "social media"
                )}
              </a>
            </p>
          </div>
          <div className="footerSitemap">
            <h4>Sitemap</h4>
            {aboutMeSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.aboutMeTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
              </a>
            )}
            {serviceSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
              </a>
            )}
            {bottomSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.otherTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.otherTitle)?.value}
              </a>
            )}
          </div>
        </div>
        <div className="footerBottom">
          <p>
            Webpage created with{" "}
            <a
              href="https://easyzilla.com"
              style={{ color: `rgb(${secondaryFont})` }}
              rel="author"
            >
              <b>easyzilla</b>
            </a>
          </p>
          <div
            style={{
              display:
                !serviceSectionEnabled && !bottomSectionEnabled
                  ? "none"
                  : "flex",
            }}
            className="toTopButton"
          >
            <a
              href="#top"
              style={{ color: `rgb(${secondaryFont})` }}
              rel="author"
            >
              <b>back to top</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
