import { CiLocationOn, CiTextAlignLeft } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { FaMoneyBillWave } from "react-icons/fa";
import { RiUserLine } from "react-icons/ri";
import { IoLinkOutline } from "react-icons/io5";
import {
  PiFlagBannerFoldLight,
  PiFlagBannerFoldFill,
  PiFlagBannerFoldDuotone,
} from "react-icons/pi";

export function iconRenderMapper(iconName: string) {
  if (iconName) {
    switch (iconName) {
      case "location":
        return <CiLocationOn />;
      case "email":
        return <HiOutlineMail />;
      case "phone":
        return <FiPhone />;
      case "user":
        return <RiUserLine />;
      case "money":
        return <FaMoneyBillWave />;
      case "flag":
        return <PiFlagBannerFoldLight />;
      case "flag-fill":
        return <PiFlagBannerFoldFill />;
      case "flag-alt":
        return <PiFlagBannerFoldDuotone />;
      case "text":
        return <CiTextAlignLeft />;
      case "link":
        return <IoLinkOutline />;
      default:
        break;
    }
  }
}

export function iconNameMapper(inputName: string) {
  if (inputName) {
    switch (inputName.toLowerCase()) {
      case "located":
      case "location":
      case "locations":
      case "address":
        return "location";
      case "email":
      case "e-mail":
        return "email";
      case "phone":
      case "phone number":
      case "phone #":
        return "phone";
      case "owner":
      case "operator":
      case "owner / operator":
      case "owner/operator":
        return "user";
      case "payment":
      case "payments":
      case "pay":
      case "receivable payments":
        return "money";
      default:
        break;
    }
  }
  return null;
}
