import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Home.css";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../auth/AuthProvider";
import { GET_ACCOUNT_QUERY } from "../../api/Requests";
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiClose } from "react-icons/tfi";
import { Session } from "@supabase/supabase-js";

export interface HomeOutletContext {
  account: {
    accountId: string;
    email: string;
    createdOn: string;
    updatedOn: string;
  };
}

// Easyzilla color palette
// Primary: #0538af
// Secondary: #f5f5f5
// Secondary Helper: #FFFFFF
// Accent: #FF7676

export default function Home() {
  const { session } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  function toggleOpenMenu() {
    setOpenMenu(val => !val);
  }

  // Only call account data if there is a session
  const { data } = useQuery(GET_ACCOUNT_QUERY, { skip: session === null });
  return (
    <div className="home">
      <HomeMenu
        openMenu={openMenu}
        toggleOpenMenu={toggleOpenMenu}
        session={session}
      />
      {session && (
        <div className="homeOutlet">
          <Outlet context={data} />
        </div>
      )}
      {!session && (
        <div>
          You are not authorized to see this page please{" "}
          <Link to="/login">Login</Link>
        </div>
      )}
    </div>
  );
}

interface HomeMenuProps {
  openMenu: boolean;
  toggleOpenMenu: () => void;
  session: Session | null;
}

function HomeMenu({ openMenu, toggleOpenMenu, session }: HomeMenuProps) {
  return (
    <div className="homeMenu">
      <div className="homeMenuContent">
        <div className="homeMenuTitle">
          <h2>Easyzilla</h2>
        </div>
        <div className="homeMenuItems">
          <Link className="homeMenuItem" to="/home/">
            Home
          </Link>
          <Link className="homeMenuItem" to="/home/account">
            Account
          </Link>
          <Link className="homeMenuItem" to="/home/webpage#top">
            Webpage
          </Link>
          <Link className="homeMenuItem" to="/home/example#top">
            Example
          </Link>
          {session?.user?.email === "admin@paulpeters.me" && (
            <Link className="homeMenuItem" to="/home/admin">
              Admin
            </Link>
          )}
        </div>
        <div className="mobileHomeMenu">
          {openMenu && (
            <div className="mobileHomeMenuItems">
              <Link className="mobileHomeMenuItem" to="/home/">
                Home
              </Link>
              <Link className="mobileHomeMenuItem" to="/home/account">
                Account
              </Link>
              <Link className="mobileHomeMenuItem" to="/home/webpage">
                Webpage
              </Link>
              <Link className="mobileHomeMenuItem" to="/home/example">
                Example
              </Link>
            </div>
          )}
          <button
            type="button"
            className="mobileHomeMenuButton"
            onClick={() => toggleOpenMenu()}
          >
            {openMenu ? <TfiClose /> : <RxHamburgerMenu />}
          </button>
        </div>
      </div>
      <div className="homeMenuDashboard">
        <p>Your Webpage Status:</p>
        <Link to="/home/">unpublished</Link>
      </div>
    </div>
  );
}
